import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import moment from "moment";
import style from "./EditObjective.module.scss";
import * as actions from "store/actions/index";
import {
  scrollToTop,
  requiredFieldMissing,
  errorToast,
  successToast,
} from "util/general";
import { endOfDay, toDate } from "util/dateUtils";
import storage from "util/storage";
import { useQuery } from "util/utils";
import Spinner from "shared/Spinner/Spinner";
import KeyResult from "./KeyResult";
import OverlayError from "../OverlayError/OverlayError";
import ObjectiveInfoSideBar from "../ObjectiveInfoSideBar";
import DurationDateSelector from "../../DurationDateSelector/DurationDateSelector";
import SubmitBox from "../../SubmitBox/SubmitBox";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import Select from "react-select";

const EditObjective = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeBand = useQuery().get("band");
  let loggedInUser = storage.getUser();
  const {
    isLoading,
    objective,
    suggestionTags,
    selfObjectiveData,
    objectiveValidation,
    keyResultErrors,
    updateObjectiveResponse,
    predefinedObjective,
  } = useSelector((state) => state.objectives);
  const targetTitle = useRef(null);
  const [keyResultPoints, setKeyResultPoints] = useState(
    objective && objective.keyPoints
  ); //keyresults keypoints (dynamic handle)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minDate, setMinDate] = useState(new Date());
  const [deletedKeyId, setDeletedKeyId] = useState({
    keyId: [],
    objectiveId: [],
  });
  const [durationTime, setDurationTime] = useState(0);
  const [incrementId, setIncrementId] = useState(10291092);
  const [addKeyPointButton, setAddKeyPointButton] = useState(true);
  const [editable, setEditable] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagId, setTagId] = useState([]);
  const [objectiveTitle, setObjectiveTitle] = useState(
    objective && objective.title
  );
  const [draftObjective, setDraftObjective] = useState(null);
  const [weightage, setWeightage] = useState(objective?.weightage);
  const [isOther, setIsOther] = useState(objective?.isOther);
  const [errors, setErrors] = useState({});
  const objectiveId = props.match.params.id;
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const managerIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };

  useEffect(() => {
    setWeightage(objective?.weightage);
  }, [objective?.weightage]);

  useEffect(() => {
    setIsOther(objective?.isOther);
  }, [objective?.isOther]);

  useEffect(() => {
    let requestBody = {
      employee: employeeIds,
      manager: managerIds,
    };
    employeeIds &&
      managerIds &&
      dispatch(actions.getUserObjectiveWithManagerEmployeePair(requestBody));
  }, []);

  let a = moment(startDate);
  let b,
    duration = null;
  if (endDate && startDate) {
    b = moment(endDate).add("day", 1);
    duration = b.diff(a, "days");
  }

  useEffect(() => {
    if (
      loggedInUser?.employeeId &&
      objective?.createdBy?.employeeId &&
      loggedInUser?.employeeId !== objective?.createdBy?.employeeId
    ) {
      history.push(routePath.HOME);
    }
  }, [loggedInUser?.employeeId, objective?.createdBy?.employeeId]);

  const deleteKeyResultPoint = (keyId, index) => {
    let errors = null;
    errors = keyResultErrors.filter((item) => item.key !== index);
    dispatch(actions.SetKeyResultErrors(errors));

    setAddKeyPointButton(true);
    keyResultPoints.splice(index, 1);
    setKeyResultPoints([...keyResultPoints]);
    let arrayKeyId = deletedKeyId.keyId;
    let arrayObjectiveId = deletedKeyId.objectiveId;
    if (typeof keyId == "number") {
      arrayKeyId.push(keyId);
      arrayObjectiveId.push(objectiveId);
    }
    setDeletedKeyId({
      keyId: arrayKeyId,
      objectiveId: arrayObjectiveId,
    });
  };
  const objectiveAddedHandler = () => {
    setIncrementId(incrementId + 3);
    setAddKeyPointButton(false);
    setKeyResultPoints([
      ...keyResultPoints,
      {
        keyId: "KEYID" + incrementId,
        description: "",
        type: "",
        start: "",
        end: "",
        weightage: "",
        stretch: false,
      },
    ]);
  };
  useEffect(()=>{
    return () => {
    dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
    }
  },[])

  // ++========= ADD Key part ++======++
  useEffect(() => {
    if (updateObjectiveResponse) {
      if (updateObjectiveResponse.success) {
        successToast(updateObjectiveResponse.message);
        dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
        if (updateObjectiveResponse.redirectTo === "CREATED_OBJECTIVE") {
          history.push({
            pathname: routePath.ALL_OBJECTIVES,
            query: { tab: "MyCreatedObjective" },
          });
        } else if (updateObjectiveResponse.redirectTo === "ASSIGN_OBJECTIVE") {
          history.push(
            `${routePath.ASSIGNED_OBJECTIVE}/${props.match.params.id}?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`
          );
        }
      } else {
        errorToast(updateObjectiveResponse.message);
      }
    }
  }, [updateObjectiveResponse]);

  // comment for UI
  useEffect(() => {
    dispatch(actions.fetchTag());
    dispatch({ type: "CLEAR_OBJECTIVES_STATE" });
    if (employeeId) {
      dispatch(
        actions.GetAssignedUserObjective(
          props.match.params.id,
          employeeIds,
          managerIds
        )
      );
    } else {
      dispatch(actions.GetObjective(props.match.params.id));
    }
    scrollToTop(500);
  }, []);

  useEffect(() => {
    if (objective && objective.startDate && objective.endDate) {
      let a = moment(objective.startDate).add("day", 1);
      let b,
        duration = null;
      if (objective.endDate) {
        b = moment(objective.endDate);
        duration = b.diff(a, "days");
      }
      setDurationTime(duration);
    }
    setStartDate(objective && toDate(objective.startDate));
    setEndDate(objective && toDate(objective.endDate));
    setKeyResultPoints(objective && objective.keyPoints);
  }, [objective]);

  useEffect(() => {
    if (
      objective &&
      (objective.definedBy === "UNIVERSITY" ||
        objective.definedBy === "SERVICE_LINE")
    ) {
      setEditable(false);
    } else {
      setEditable(true);
    }
    if (/* Object.keys(objective).length > 0 */ objective != null) {
      let errorsArr = [...objectiveValidation];
      if (objective.title === "") {
        let index = errorsArr.findIndex((x) => x.key === "title");
        index === -1 && errorsArr.push({ key: "title", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "title");
      }

      if (objective.keyPoints == null || keyResultErrors.length > 0) {
        let index = errorsArr.findIndex((x) => x.key === "keyPoints");
        index === -1 && errorsArr.push({ key: "keyPoints", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "keyPoints");
        setAddKeyPointButton(true);
      }
      dispatch(actions.SetObjectiveValidation(errorsArr));
    }
  }, [objective, keyResultErrors]);

  useEffect(() => {
    if (objective) {
      setObjectiveTitle(objective.title);
      setTags(objective.tags ? objective.tags : []);
    }
    dispatch(
      actions.getPrdefinedObjective(
        loggedInUser?.podInformation,
        loggedInUser?.country
      )
    );
  }, [objective]);

  const addError = (key) => {
    let errors = [...objectiveValidation];
    let error = { key: key, showError: true };

    let index = errors.findIndex((x) => x.key === key);
    index === -1 ? errors.push(error) : (errors[index].showError = true);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const removeError = (key) => {
    let errors = null;
    errors = objectiveValidation.filter((item) => item.key !== key);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const objectiveHandler = (item) => {
    setErrors({ ...errors, OKR_title: false });
    if (item?.value === "Other") {
      setWeightage("");
      setObjectiveTitle("");
      setIsOther(true);
    } else {
      setObjectiveTitle(item.value);
      setWeightage(item?.weightage);
      setIsOther(false);
    }

    if (item.value.trim()) {
      if (objective.title !== item.value && item?.value !== "Other") {
        setDraftObjective({
          ...draftObjective,
          title: item.value.trim(),
          weightage: item.weightage,
          bandWiseObjectiveId: item.bandWiseObjectiveId,
          band: item.band,
          isOther: false,
        });
      } else if (objective.title !== item.value && item?.value === "Other") {
        setDraftObjective({
          ...draftObjective,
          isOther: true,
        });
      }
      removeError("title");
    } else {
      addError("title");
    }
  };

  const otherObjectiveHandler = (event) => {
    if (objective.title !== event.target.value.trim()) {
      setDraftObjective({
        ...draftObjective,
        title: event.target.value.trim(),
        isOther: true,
      });
      removeError("title");
    } else {
      addError("title");
    }
  };

  const weightageHandler = (event) => {
    setWeightage(event.target.value);
    if (event.target.value.trim()) {
      if (objective.weightage !== event.target.value) {
        setDraftObjective({
          ...draftObjective,
          weightage: event.target.value.trim(),
          isOther: true,
        });
      }
      removeError("weightage");
    } else {
      addError("weightage");
    }
  };

  const descriptionHandler = (event) => {
    if (objective.description !== event.target.value) {
      setDraftObjective({
        ...draftObjective,
        description: event.target.value || "",
      });
    }
  };

  const saveObjectiveHandler = () => {
    if (draftObjective?.title === null) {
      setErrors({ ...errors, OKR_title: true });
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("otherTitle")?.value === "") {
      setErrors({ ...errors, OKR_otherTitle: true });
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("weightage")?.value === "") {
      setErrors({ ...errors, ORK_weightage: true });
      requiredFieldMissing();
      return;
    }
    if (
      document.getElementById("weightage")?.value > 100 ||
      document.getElementById("weightage")?.value < 1
    ) {
      errorToast("weightage should not be less than 1 and more than 100");
      return;
    }

    let weightageSum = objective.definedBy === "UNIVERSITY" ? 100 : 0;
    if (keyResultPoints && objective.definedBy !== "UNIVERSITY") {
      for (let el in keyResultPoints) {
        weightageSum += parseFloat(keyResultPoints[el].weightage);
      }
    }
    if (weightageSum < 100) {
      errorToast(`Add more ${100 - weightageSum}% weightage`);
    } else if (weightageSum > 100) {
      errorToast(`Delete ${weightageSum - 100}% weightage`);
    } else if (!weightageSum) {
      requiredFieldMissing();
    }else if (draftObjective?.band && employeeBand && employeeBand?.toString() !== draftObjective?.band) {
      errorToast("OKR band and selected employee band is different");
      return;
    }else {
      let editedKeyPoints = keyResultPoints
        .filter((value) => value.edit)
        .map((value) => {
          if (typeof value.keyId == "string") {
            delete value.keyId;
          }
          delete value.edit;
          return value;
        });
      if (objectiveValidation.length > 0) {
        const submitedErrors = objectiveValidation.map((err) => ({
          ...err,
          showError: true,
        }));

        dispatch(actions.SetObjectiveValidation(submitedErrors));
        requiredFieldMissing();
      } else if (
        deletedKeyId.keyId.length > 0 &&
        deletedKeyId.objectiveId.length > 0
      ) {
        if (employeeId)
          dispatch(
            actions.DeleteEditedAssignedKeyPoint(
              deletedKeyId,
              "ASSIGN_OBJECTIVE",
              draftObjective,
              editedKeyPoints,
              props.match.params.id,
              {
                ...employeeIds,
                parentGroupName: objective.employeeData.parentGroupName,
                subGroupName: objective.employeeData.subGroupName,
              },
              {
                managerId: managerIds.employeeId,
                managerSubGroupId: loggedInUser?.subGroupId,
                managerSubGroupName: loggedInUser?.subGroupName,
                managerParentGroupId: loggedInUser?.parentGroupId,
                managerParentGroupName: loggedInUser?.parentGroupName,
              }
            )
          );
        else
          dispatch(
            actions.DeleteEditedKeyPoint(
              deletedKeyId,
              "CREATED_OBJECTIVE",
              draftObjective,
              editedKeyPoints,
              objective.objectiveId
            )
          );

        if (tagId.length > 0) {
          if (employeeId)
            dispatch(
              actions.DeleteAssignedTags(tagId, objective.userObjectiveId)
            );
          else
            dispatch(actions.DeleteTags(tagId, objective.objectiveId, false));
        }
      } else if (draftObjective || editedKeyPoints.length > 0) {
        if (draftObjective || editedKeyPoints.length > 0 || tagId.length > 0) {
          if (employeeId) {
            if (
              selfObjectiveData.totalWeightagae -
                objective.weightage +
                parseInt(weightage) >
              100
            ) {
              errorToast(
                "Please check total weightage, as it is exceeding 100%"
              );
              return;
            } else {
              dispatch(
                actions.UpdateAssignedObjective(
                  draftObjective,
                  editedKeyPoints,
                  props.match.params.id,
                  {
                    ...employeeIds,
                    parentGroupName: objective.employeeData.parentGroupName,
                    subGroupName: objective.employeeData.subGroupName,
                  },
                  {
                    managerId: managerIds.employeeId,
                    managerSubGroupId: loggedInUser?.subGroupId,
                    managerSubGroupName: loggedInUser?.subGroupName,
                    managerParentGroupId: loggedInUser?.parentGroupId,
                    managerParentGroupName: loggedInUser?.parentGroupName,
                  },
                  "ASSIGN_OBJECTIVE",
                  tagId
                )
              );
            }
          } else
            dispatch(
              actions.UpdateObjective(
                draftObjective,
                editedKeyPoints,
                "CREATED_OBJECTIVE",
                objective.objectiveId,
                tagId
              )
            );
        }
      } else {
        if (tagId.length > 0) {
          if (employeeId)
            dispatch(
              actions.DeleteAssignedTags(tagId, objective.userObjectiveId)
            );
          else
             dispatch(actions.DeleteTags(tagId, objective.objectiveId, false, true));

          history.push({
            pathname: "/objectives/all-objectives",
            query: { tab: "MyCreatedObjective" },
          });
        } else {
          if (employeeId) {
            history.push(
              `/objectives/all-objectives/assigned-objective/${props.match.params.id}?empId=${employeeId}&pgId=${objective.parentGroupId}&sgId=${objective.subGroupId}`
            );
          } else {
            history.push({
              pathname: "/objectives/all-objectives",
              query: { tab: "MyCreatedObjective" },
            });
          }
        }
      }
    }
  };

  const cancelHandler = () => {
    if (employeeId) history.push(routePath.TEAMS_OBJECTIVE);
    else
      history.push({
        pathname: "/objectives/all-objectives",
        query: { tab: "MyCreatedObjective" },
      });
  };
  const handleAddition = (tag) => {
    let index =
      tags && tags.length > 0 && tags.findIndex((x) => x.name === tag.name);
    if (index === -1 || index === false) {
      setTags([...tags, { ...tag }]);
      if (draftObjective && draftObjective.tags) {
        setDraftObjective({
          ...draftObjective,
          tags: [...draftObjective.tags, { ...tag }],
        });
      } else {
        setDraftObjective({
          ...draftObjective,
          tags: [tag],
        });
      }
    }
  };

  const handleDelete = (i) => {
    let id = tagId.concat(tags[i].id);
    setTagId(id);
    tags && tags.splice(i, 1);
    setTags(tags);
  };

  const handleStartDate = (date) => {
    setDraftObjective({
      ...draftObjective,
      startDate: date,
    });
    if (date > new Date()) {
      setMinDate(date);
    }
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setDraftObjective({
      ...draftObjective,
      endDate: endOfDay(date),
    });
    setEndDate(date);
  };
  // Loading Screen   // comment for UI
  if (isLoading) {
    return <Spinner />;
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: errors.OKR_title && "1px solid #ff8b67",
    }),
  };

  const handleOnKeyPress = (e) => {
    e = e || window.event;
    let charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (e.charCode < 48 || !charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              <Link to="/objectives/my-objectives">Objectives</Link>
              <span>
                <Link
                  to={{
                    pathname: `/objectives/all-objectives`,
                    query: {
                      tab: `MyCreatedObjective`,
                    },
                  }}
                >
                  All Objectives
                </Link>
              </span>
              <span>Edit Objective</span>
            </span>
          </Col>
          <Col lg={8}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Edit Objective Details</h2>
                  <h3>Objectives should be qualitative and aspirational</h3>
                </div>
                <div className="box-info d-none">
                  <span></span>
                </div>
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label">
                        What is your objective ?
                        <span className="text-danger"> * </span>
                      </label>
                      <div className="tags_wrapper">
                        <Select
                          options={predefinedObjective}
                          className="multi-select"
                          classNamePrefix="select"
                          name="objective"
                          value={
                            { label: isOther ? "Other" : objectiveTitle } || ""
                          }
                          onChange={objectiveHandler}
                          ref={targetTitle}
                          styles={customStyles}
                        />
                      </div>
                      <OverlayError
                        target={targetTitle}
                        objectiveValidation={objectiveValidation}
                        errorFor="title"
                      />
                    </div>
                  </Col>
                </Row>
                {isOther && (
                  <Row className={style.create_objective_wrapper}>
                    <Col className={style.create_objective_data_wrapper}>
                      <Form.Group className={style.formGroup}>
                        <Form.Label>
                          Add Objective Name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="200"
                          id="otherTitle"
                          className={[
                            errors && errors.OKR_otherTitle
                              ? style.errorBox
                              : "",
                            style.weightageBox,
                          ].join(" ")}
                          defaultValue={objectiveTitle}
                          // defaultValue={draftObjective?.title || ""}
                          onBlur={(e) => otherObjectiveHandler(e)}
                          onChange={(e) => {
                            if (e.target.value.trim()) {
                              setErrors({ ...errors, OKR_otherTitle: false });
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <Form.Group className={style.formGroup}>
                      <Form.Label>
                        Weightage<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="weightage"
                        maxLength="200"
                        className={[
                          errors && errors.ORK_weightage ? style.errorBox : "",
                          style.weightageBox,
                        ].join(" ")}
                        disabled={!isOther}
                        defaultValue={weightage}
                        onWheel={(e) => e.target.blur()} // stopping changing numbers with mouse scrolling
                        onChange={(e) => {
                          weightageHandler(e);
                          if (e.target.value.trim()) {
                            setErrors({ ...errors, ORK_weightage: false });
                          }
                        }}
                        onKeyPress={handleOnKeyPress}
                        onKeyDown={handleOnKeyDown}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {editable && (
                  <Row className={style.create_objective_wrapper}>
                    <Col className={style.create_objective_data_wrapper}>
                      <div className="form-group">
                        <label className="form-label"> Description </label>
                        <div
                          className={[style.textbox_wrapper, "border-0"].join(
                            " "
                          )}
                        >
                          <div className="textarea-wrapper ">
                            <Form.Control
                              as="textarea"
                              maxLength="512"
                              placeholder="Add Description"
                              onBlur={(e) => descriptionHandler(e)}
                              defaultValue={objective && objective.description}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                {/* un commented because this requirement given by hr  */}
                {editable && (
                  <Row
                    className={[
                      style.create_objective_wrapper,
                      "tags_wrapper",
                    ].join(" ")}
                  >
                    <Col className={style.create_objective_data_wrapper}>
                      <div className="form-group">
                        <label className="form-label"> Tags </label>
                        <div className={style.textbox_wrapper}>
                          <ReactTags
                            noSuggestionsText="Click enter to add new tag"
                            tags={tags}
                            allowNew={true}
                            suggestions={suggestionTags ? suggestionTags : []}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label">Define Key Results</label>
                      {keyResultPoints &&
                        keyResultPoints.map((item, index) => {
                          const keyPoint = {
                            keyId: item.keyId,
                            description: item.description,
                            type: item.type,
                            start: item.start,
                            end: item.end,
                            stretch: item.stretch,
                            hours: item.hours,
                            weightage: item.weightage,
                            progress: item.progress,
                          };
                          return (
                            <KeyResult
                              key={item.keyId}
                              setAddKeyPointButton={setAddKeyPointButton}
                              keyResultPoints={keyResultPoints}
                              setKeyResultPoints={setKeyResultPoints}
                              deleteKeyResultPoint={deleteKeyResultPoint}
                              disabled={!editable}
                              style={style}
                              index={index}
                              keyPoint={keyPoint}
                            />
                          );
                        })}
                    </div>
                  </Col>
                </Row>
                {editable && (
                  <Row className="mt-3">
                    <Col>
                      <span // || keyError
                        className={[
                          "text_link",
                          !addKeyPointButton && "disabled",
                        ].join(" ")}
                        onClick={() => objectiveAddedHandler()}
                      >
                        Add New Key Result
                      </span>
                    </Col>
                  </Row>
                )}
              </div>
              {employeeId && (
                <DurationDateSelector
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  duration={duration || durationTime}
                  startDate={startDate}
                  minDate={minDate}
                  endDate={endDate}
                />
              )}
            </div>
            <SubmitBox
              handleSubmit={() => saveObjectiveHandler(objective?.objectiveId)}
              handleSecond={cancelHandler}
              name="Save"
              secondBtnName="Cancel"
              updatedDate={objective?.updatedDate}
            />
          </Col>
          <Col lg={4}>
            {objective && (
              <ObjectiveInfoSideBar
                objective={objective}
                type={employeeId ? true : false}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditObjective;
