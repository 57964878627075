import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import ObjectiveHTML from "./objectiveHTML";
import { IC_EXPAND, IC_COLLAPSE } from "../../../../const/imgCost";
import * as actions from "../../../../store/actions/index";
import style from "./MemberProfileObjecive.module.scss";
import InitiativeModal from "../../SelfAssessment/Components/InitiativeModal";
import KeyResultCommentModal from "./ObjeciveModal/KeyResultCommentModal";
import { toast } from "react-toastify";
import SlideToast from "../../../Toast/Toast";
import storage from "../../../../util/storage";
import { toFixedNumber } from "../../../../util/utils";
// import { useLocation } from "react-router-dom";

function MemberProfileObjective(props) {
  const { managerList, managerWiseGrade } = props;
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const loggedInUserEmpId = loggedInUser?.employeeId;
  const [resultModal, setResultModal] = useState(false);
  const [keyPoint, setkeyPoint] = useState();
  const [objective, setObjective] = useState();
  const [isModal, setIsModel] = useState(false);
  const { expanded, privateAssessmentObjectiveErrors, quaterlyCycleData } = useSelector((state) => state.performance);
  const [eventValue, setEventValue] = useState("");
  const [userObjective, setUserObjective] = useState(null);

  // let showRatingDropDown = useLocation();

  const cycleID = props?.rowData?.cycleId;
  useEffect(() => { dispatch(actions.getQuarterlyCycle(cycleID)); }, [dispatch, cycleID]);

  useEffect(() => {
    let errors = [];
    if (managerList?.length > 0) {
      managerList.map((manager) => {
        if (
          manager.employeeId === loggedInUserEmpId ||
          manager.objectives.find((v) => v.updatedByManager?.employeeId === loggedInUserEmpId) ||
          manager.objectives.find((v) => v.overrideBy?.employeeId === loggedInUserEmpId)
        ) {
          if (manager.objectives.find((value) => value.definedBy === "UNIVERSITY")) {
            manager.objectives
              .filter((value) => value.definedBy === "UNIVERSITY" && value.status !== "COMPLETED")
              .map((value) => {
                if (!value.ratingRemarks) {
                  let error = {
                    objectiveId: value.userObjectiveId,
                    error: true,
                    isSubmit: false,
                  };
                  errors.push(error);
                }
                return value;
              });
          }
          if (manager.objectives.find((value) => value.definedBy === "MANAGER" || value.definedBy === "SERVICE_LINE")) {
            manager.objectives
              .filter(
                (value) =>
                (value.definedBy === "MANAGER" || value.definedBy === "SERVICE_LINE") && value.status !== "COMPLETED" && value?.weightage !== null
              )
              .map((value) => {
                value.keyPoints.map((point) => {
                  if (!point.ratingRemarks) {
                    let error = {
                      keyId: point.keyId,
                      objectiveId: value.userObjectiveId,
                      error: true,
                      isSubmit: false,
                    };
                    errors.push(error);
                  }
                  return point;
                });
                return value;
              });
          }
        }
        return manager;
      });
    }
    dispatch({ type: "SET_PRIVATE_ASSESSMENT_OBJECTIVE_ERROR", error: errors });
  }, [managerList, dispatch, loggedInUserEmpId]);

  const saveKeyResultComment = (comment, keypoint, objective) => {
    setResultModal(false);
    toast.success(<SlideToast title="Awesome!" message={"Saved successfully"} error={false} />, {
      toastId: "SAVE_SUCCESS",
    });
    if (comment !== keypoint.comment) {
      dispatch(
        actions.SavePrivateAssessmentObjectiveRatingComment({
          privateAssessmentType: props.isPage,
          type: "teams_objective",
          keyComment: comment || keypoint.comment,
          privateAssessmentId: props.rowData.privateAssessmentId,
          userObjectiveId: objective.userObjectiveId,
          keyId: keypoint.keyId,
          employeeData: props.rowData.employeeData,
          cycleId: props.rowData.cycleId,
        })
      );
    }
  };

  const checkObjectiveError = (objective) => {
    if (objective.definedBy === "UNIVERSITY") {
      let indexError =
        privateAssessmentObjectiveErrors &&
        privateAssessmentObjectiveErrors.findIndex(
          (value) => value.objectiveId && value.isSubmit && value.objectiveId === objective.userObjectiveId
        );
      if (indexError !== -1 && indexError !== undefined) return true;
      else return false;
    } else {
      return privateAssessmentObjectiveErrors;
    }
  };

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  let aggregateText = "Aggregate Grade";
  let aggregateRating = props.rowData.grade;
  if (props.rowData.gradeOrRatingOrBoth === "BOTH") {
    aggregateText = "Aggregate Grade & Rating";
    aggregateRating = props.rowData.grade
      ? props.rowData.grade + "/" + toFixedNumber(props.rowData?.objectiveRatings, 2)
      : "-";
  } else if (props.rowData.gradeOrRatingOrBoth === "RATING") {
    aggregateText = "Aggregate Rating";
    aggregateRating = toFixedNumber(props.rowData?.objectiveRatings, 2);
  }
  return (
    <div>
      <div className={["box", style.idp_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Objective Tracking</h2>
            <h3>Objective status and progress helps tracking down things in easy way</h3>
          </div>
        </div>
        {props.isPage !== "SELF" && (
          <div className={["box-light-gradient", style.overall_obj].join(" ")}>
            <h3>
              {aggregateText} : <strong>{aggregateRating || "-"}</strong>
            </h3>
          </div>
        )}
        <div className={style.member_manager_wrapper}>
          {managerList.map((manager) => {
            let overAllGradeView = false;
            if (
              loggedInUserEmpId === manager.employeeId ||
              props.isPage === "EMPLOYEE" ||
              (props.hrView && props.isPage !== "SELF") ||
              props.isPage === "REVIEWING" ||
              manager.objectives?.find((v) => v.updatedByManager?.employeeId === loggedInUserEmpId)
            ) {
              overAllGradeView = true;
            }
            let managerRating =
              managerWiseGrade?.length && managerWiseGrade.find((item) => item.managerId === manager.employeeId);
            let gradeRating =
              managerRating && props.rowData.gradeOrRatingOrBoth === "BOTH"
                ? managerRating.grade
                  ? managerRating.grade + "/" + toFixedNumber(managerRating.rating, 2)
                  : "-"
                : props.rowData.gradeOrRatingOrBoth === "RATING"
                  ? toFixedNumber(managerRating.rating, 2) ?? "-"
                  : managerRating?.grade ?? "-";
            // let gradeRating = getPerformanceGrade(
            //   getOverAllRating(manager.objectives, props.rowData.quarterStartDate, props.rowData.quarterEndDate)
            // );

            return (
              <div className={style.manager_list} key={manager.employeeId}>
                <div className={style.topbar}>
                  <span className={style.collapse_img}>
                    <span>
                      <ReactSVG
                        src={expanded === manager.employeeId ? IC_COLLAPSE : IC_EXPAND}
                        onClick={() => {
                          toggleExpander(manager.employeeId);
                          setEventValue("");
                        }}
                      />
                    </span>
                  </span>
                  <div
                    className={style.manager_info}
                    onClick={() => {
                      toggleExpander(manager.employeeId);
                      setEventValue("");
                    }}
                  >
                    <h4>
                      Objective Assigned By : <strong>{manager.empName}</strong>
                      {props.hrView && (
                        <span className={style.managerRoleTooltip}>
                          <span>{manager.isRVM && manager.isRPM ? "(RM,RVM)" : manager.isRVM ? "(RVM)" : "(RM)"} </span>
                          <div className={style.managerRoleTooltipInfo}>
                            {manager.isRVM && manager.isRPM
                              ? "Reporting Manager, Reviewing Manager"
                              : manager.isRVM
                                ? "Reviewing Manager"
                                : "Reporting Manager"}
                          </div>
                        </span>
                      )}
                    </h4>
                    {/* {overAllGradeView ? <span>Overall Progress : {gradeRating}</span> : null} changes for offline ratings*/} 
                    {overAllGradeView ? <span>Overall Progress : {props.rowData.offlineRatingStatus === "Rating corrected" ? '-' : gradeRating}</span> : null}
                  </div>
                </div>

                {expanded === manager.employeeId && (
                  
                  <Accordion defaultActiveKey="0" className={[style.obj_accordion, "custom-accordion"].join(" ")}>
                    {manager &&
                      manager.objectives
                        .filter((obj) => obj.status !== "DRAFTED")
                        .map((objective, index) => (
                          <ObjectiveHTML
                            isDisabledRating={
                              props.isSubmitted || objective?.weightage === undefined || objective?.weightage === null || (props.isPage === "REPORTING" && objective.status === "COMPLETED" && !objective.ratingDoneOutSideOfPMS)
                            }
                            isOldRatingOption = {quaterlyCycleData?.showRatingDropDown === null ? true : false }
                            isOneToFourRatings = {quaterlyCycleData?.is1To4RatingCycle != null && quaterlyCycleData?.showRatingDropDown !== null}
                            isHideRatingDropDown = { quaterlyCycleData?.showRatingDropDown === null ? false : !quaterlyCycleData?.showRatingDropDown }
                            allManagerStatus={props.allManagerStatus}
                            key={objective.objectiveId + index}
                            setAssessmentError={props.setAssessmentError}
                            assessmentError={props.assessmentError}
                            objectiveError={checkObjectiveError(objective)}
                            loggedInUser={loggedInUser}
                            reviewer={props.reviewer}
                            isPage={props.isPage}
                            setkeyPoint={setkeyPoint}
                            index={index}
                            objective={objective}
                            setObjective={setObjective}
                            setResultModal={setResultModal}
                            managerId={props.managerId}
                            manager={manager}
                            setEventValue={setEventValue}
                            eventValue={eventValue}
                            setIsModel={setIsModel}
                            isModal={isModal}
                            setUserObjective={setUserObjective}
                            privateAssessmentData={props.rowData}
                            isPrivateAssessmentSubmit={props.isSubmitted}
                            hrView={props.hrView}
                            employeeData={props.employeeData}
                            allManagerDetails={props.allManagerDetails}
                          />
                        ))}
                  </Accordion>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {isModal && (
        <InitiativeModal
          empName={props.employeeData?.displayName}
          show={isModal}
          hide={() => setIsModel(!isModal)}
          plans={userObjective.plans}
          percentage={userObjective.percentage}
          status={userObjective.status}
          title={userObjective.title}
        />
      )}
      {resultModal && (
        <KeyResultCommentModal
          loggedInUser={loggedInUser}
          objective={objective}
          keyPoint={keyPoint}
          // isSubmitted={props.isSubmitted || objective.managerData.employeeId !== loggedInUserEmpId}
          isSubmitted={
            props.isSubmitted ||
            (objective.updatedByManager &&
              (props.allManagerDetails.find((v) => v.employeeId === objective.managerData.employeeId)
                ? loggedInUser.employeeId !== objective.updatedByManager.employeeId
                  ? true
                  : false
                : loggedInUser.employeeId !== objective.updatedByManager.employeeId
                  ? true
                  : false)) ||
            objective.status === "COMPLETED"
          }
          saveComment={saveKeyResultComment}
          hide={() => setResultModal(false)}
          show={resultModal}
        />
      )}
    </div>
  );
}

export default MemberProfileObjective;
