import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "./ReviewCycleList.module.scss";
import * as actions from "../../../store/actions/index";
import { filterArray, getGradeName } from "../../../util/general";
import { toFixedNumber } from "../../../util/utils";
import { IC_INFO } from "../../../const/imgCost";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import Spinner from "../../../shared/Spinner/Spinner";
import { noData } from "const/constValue";

export default function ReviewCycleList() {
  const dispatch = useDispatch();
  const { QuarterlyCycleListForEmployee, isLoading } = useSelector((state) => state.performance);

  const [pageOfItems, setPageOfItems] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [filteredData, setFilteredData] = useState(QuarterlyCycleListForEmployee);
  const onChangeReviewPage = (item) => setPageOfItems(item);

  useEffect(() => {
    dispatch(actions.GetQuarterlyCycleList("USER"));
  }, [dispatch]);

  useEffect(() => {
    filteredData?.length > 0
      ? setCycleData(filteredData)
      : searchFilter
      ? setCycleData(filteredData)
      : setCycleData(QuarterlyCycleListForEmployee);
  }, [filteredData, QuarterlyCycleListForEmployee, searchFilter]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (QuarterlyCycleListForEmployee && QuarterlyCycleListForEmployee.length > 0) {
      filteredData = filterArray(QuarterlyCycleListForEmployee, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={style.cycleListWrapper}>
      <Container>
        <Row>
          <Col md={12} className="mt-3">
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              Reviews<span>Ratings</span>
            </span>
          </Col>
          <Col className="col-12">
            <div className={[style.box_2, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading justify-content-between d-flex align-items-center">
                  <h2>Cycle List</h2>
                </div>
              </div>
            </div>
            <div className="outer-table-wrapper">
              <div className="box-light-gradient"></div>
              <div className={[style.custom_table, style.hr_table, "table-theme-1"].join(" ")}>
                {pageOfItems && pageOfItems.length > 0 ? (
                  <Table hover responsive className={style.tab_table_responsive}>
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Cycle</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageOfItems.map((item, index) => {
                        let ratingText = "Aggregate Grade";
                        let grade = item.userGrade;
                        if (item.gradeOrRatingOrBoth === "BOTH") {
                          ratingText = "Aggregate Grade & Rating";
                          grade = item.userGrade ? item.userGrade + "/" + toFixedNumber(item.userRating, 2) : "";
                        } else if (item.gradeOrRatingOrBoth === "RATING") {
                          ratingText = "Aggregate Rating";
                          grade = toFixedNumber(item.userRating, 2);
                        }
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={{ pathname: "/performance/dashboard/cycle-detail/" + item.cycleId }}>
                                {item.period}
                              </Link>
                            </td>
                            <td>
                              <span>
                                {item.cycle}
                                <div className={style.show_grade_tooltip}>
                                  <div className={style.info_icon}>
                                    <ReactSVG src={IC_INFO} title="" alt="info" />
                                  </div>
                                  <div className={style.show_grade_tooltip_info}>
                                    {ratingText} : {grade || "-"}
                                    <span>{getGradeName(item.userGrade)}</span>
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td>
                              <Link to={{ pathname: "/performance/dashboard/cycle-detail/" + item.cycleId }}>
                                <span className={item.status === "Completed" ? style.inprogress : style.ended}>
                                  {item.status === "Completed" ? "View" : item.status}
                                </span>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {cycleData?.length > 0 && (
          <Pagination pageSize={"10"} items={cycleData} resetData={resetData} onChangePage={onChangeReviewPage} />
        )}
      </Container>
    </div>
  );
}
